import React from 'react';
import './CreateCommitment.css';

import { Client } from "@notionhq/client"
import axios from 'axios';
import { Link, Redirect } from 'react-router-dom';

import Data from '../data/data.js';

import AppHeader from '../components/AppHeader';

import InformationIcon from '../assets/icons/information.svg';

const CreateCommitment = (props) => {

  const [authState, setAuthState] = React.useState(props.auth);

  const [tasks, setTasks] = React.useState("");
  const [workHours, setWorkHours] = React.useState("");
  const [tasksLoaded, setTasksLoaded] = React.useState(false);

  const [redirect, setRedirect] = React.useState(false);

  const submitData = () => {
    axios.post(`${Data.api_urls.backend}/commitments`, {
      tasks: tasks,
      workHours: workHours,
      cognito_id: authState.authState.user.username
    })
    .then(response => {
      console.log(response);
      setRedirect(true);
    })
    .catch(error => {
      console.log(error);
    })
  }


  // notion api
  const notion = new Client({ auth: Data.notion.api_key })
  const databaseId = Data.notion.table_id;


  const getTasks = () => {
    axios.get(`${Data.api_urls.backend}/notions/get_database`, {
      params: {
        cognito_id: authState.authState.user.username,
        databaseId: databaseId,
        todayPropertyName: "実行タイミング",
        todayPropertyCondition: "本日実行のタスク",
        completePropertyName: "実行タイミング",
        completedCondition: "完了したタスク"
      }
    })
    .then(response => {
      console.log(response);
      setTasks(response.data.cards);
      setTasksLoaded(true);
    })
    .catch(error => {
      console.log(error);
    })
  }


  if (redirect == true) {
    return (
      <Redirect
        to={{
          pathname: "/"
        }}
      />
    )
  }


  return (
    <div className="app-container">
      <AppHeader
        displayButtons={true}
        auth={authState}
      />
      <div className="page-container">
        <div className="contents-wrapper">
          <div className="home-message-section page-section">
            <div className="home-header">
              <span className="header-title home-header-message-text">
                NEW COMMITMENT
              </span>
            </div>
            <div className="home-sub-header">
              <span className="header-sub-title home-header-message-text">
                WORKING HOURS
              </span>
            </div>
            <div className="home-message-content">
              <p className="create-commitment-workinghours-text">I can work ...</p>
              <div className="create-commitment-workinghours-number-section">
                <input
                  type="number"
                  className="create-commitment-workinghours-number"
                  onChange={(e) => {
                    setWorkHours(e.target.value);
                  }}
                />
                <span className="create-commitment-workinghours-number-text">hours today.</span>
              </div>

            </div>
          </div>

          <div className="home-message-section page-section">
            <div className="home-sub-header create-commitment-tasks-header-section">
              <span className="header-sub-title home-header-message-text">
                TASKS
              </span>
              {(() => {
                if (tasksLoaded == true) {
                  if (tasks.length == 0) {
                    return (
                      <span className="create-commitment-tasks-sub-header">NO TASKS DETECTED</span>
                    )
                  } else {
                    return (
                      <span className="create-commitment-tasks-sub-header">{`| ${tasks.length} TASKS DETECTED`}</span>
                    )
                  }
                }
              })()}
            </div>

            {
              tasksLoaded == false &&
              <div className="create-commitment-notion-description">
                <img src={InformationIcon} alt=""/>
                <p className="create-commitment-notion-description-text">
                  Portalに設定したPropertyとKeywordを使って、Notionで今日実行するタスクを設定し、
                  <strong>“GET ‘EM!”</strong>をタップしてください。
                </p>
              </div>
            }

            {(() => {
              if (tasksLoaded == true && tasks.length > 0) {
                return (
                  <div className="create-commitment-tasks-section">
                    {
                      tasks.map((value, index) => {
                        return (
                          <Link
                            key={index}
                            className="create-commitment-task-card element-container"
                            to={{
                              pathname: value.web_url
                            }}
                            target="_blank"
                          >
                            <div className="create-commitment-task-card-title">
                              <p className="create-commitment-task-card-title-text">{value.task_name}</p>
                            </div>

                            <div className="create-commitment-task-card-line"></div>

                            {
                              value.completed_validation == "COMPLETED"
                              ? <div className="create-commitment-task-card-status create-commitment-task-card-status-green">
                                  <p className="create-commitment-task-card-status-text">{value.completed_validation}</p>
                                </div>
                              : <div className="create-commitment-task-card-status create-commitment-task-card-gray">
                                  <p className="create-commitment-task-card-status-text">{value.completed_validation}</p>
                                </div>
                            }

                          </Link>
                        )
                      })
                    }
                  </div>
                )
              }
            })()}



          </div>


          {(() => {
            if (tasksLoaded == true && tasks.length > 0) {
              return (
                <div className="create-commitment-save-section">
                  <span className="create-commitment-save-description">If this looks right, tap <strong>“Commit”</strong></span>
                  <div
                    className="button button-black"
                    onClick={() => {
                      if (workHours == "" || workHours == 0) {
                        alert("Input Workhours");
                      } else {
                        submitData();
                      }
                    }}
                  >
                    <span>COMMIT</span>
                  </div>
                </div>
              )
            }
          })()}


          <div
            className="button button-skelton"
            onClick={() => {
              getTasks();
            }}
          >
            <span>GET &#39;EM!</span>
          </div>

        </div>
      </div>
    </div>
  )
}

export default CreateCommitment;












//
