import React from 'react';
import './Signup.css';

import { Auth } from 'aws-amplify';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';

import Data from '../data/data';

import AppHeader from '../components/AppHeader';

const Signup = (props) => {

  const [redirect, setRedirect] = React.useState(false);
  const [authState, setAuthState] = React.useState(props.auth.authState);
  const [errorMsg, setErrorMsg] = React.useState("");


  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    password: "",
    passwordConfirm: ""
  });

  React.useEffect(() => {
    console.log(props);
  }, [])

  React.useEffect(() => {
    if (authState.isAuthenticated == true) {
      setRedirect(true);
    }
  }, [authState])

  const submitData = () => {
    if (formData.name == "" || formData.email == "" || formData.password == "" || formData.passwordConfirm == "") {
      alert("Please fill all fields.")
    } else {
      if (formData.password != formData.passwordConfirm) {
        alert("Password did not match.")
      } else {

        // cognito
        Auth.signUp({
          username: formData.email,
          password: formData.password,
          attributes: {
            email: formData.email
          }
        })
        .then (response => {

          console.log("success: ", response.userSub);
          const userSub = response.userSub;

          axios.post(`${Data.api_urls.backend}/users`, {
            user_name: formData.name,
            cognito_id: userSub
          })
          .then(response => {
            Auth.signIn(formData.email, formData.password)
            .then (user => {
              console.log("success: ", user);
              setAuthState(authState => ({...authState, isAuthenticated: true}));
              setAuthState(authState => ({...authState, user: user}));
            })
            .catch(error => {
              console.log("error: ", error);
            })
          })
          .catch(error => {
            console.log(error);
          })


        })
        .catch(error => {
          console.log("error: ", error);

          switch (error.code) {
            case "UsernameExistsException":
              setErrorMsg("ユーザーがすでに存在します")
              break;
            case "InvalidParameterException":
              setErrorMsg("パスワード条件を確認してください")
              break;
            case "InvalidPasswordException":
              setErrorMsg("パスワード条件を確認してください")
              break;
            default:
              setErrorMsg("登録に失敗しました")
          }
        })

      }
    }
  }

  if (redirect == true) {
    return (
      <Redirect
        to={{
          pathname: "/"
        }}
      />
    )
  }

  return (
    <div className="app-container">
      <AppHeader displayButtons={false}/>

    <div className="page-container">
      <div className="contents-wrapper">
        <div className="page-section">
          <div className="home-header">
            <span className="header-title">
              MEMBER SIGNUP
            </span>
          </div>

          <div className="login-form signup-form-name">
            <span className="login-form-title">
              NAME
            </span>
            <input
              className="login-form-input"
              type="text"
              onChange={(e) => {
                setFormData(formData => ({...formData, name: e.target.value}))
              }}
            />
          </div>

          <div className="login-form">
            <span className="login-form-title">
              EMAIL
            </span>
            <input
              className="login-form-input"
              type="text"
              onChange={(e) => {
                setFormData(formData => ({...formData, email: e.target.value}))
              }}
            />
          </div>

          <div className="login-form">
            <span className="login-form-title">
              PASSWORD
            </span>
            <input
              className="login-form-input"
              type="password"
              onChange={(e) => {
                setFormData(formData => ({...formData, password: e.target.value}))
              }}
            />
          </div>

          <div className="login-form">
            <span className="login-form-title">
              PASSWORD CONFIRM
            </span>
            <input
              className="login-form-input"
              type="password"
              onChange={(e) => {
                setFormData(formData => ({...formData, passwordConfirm: e.target.value}))
              }}
            />
          </div>

          <div className="login-buttons-section">

            <div className="signup-error-section">
              <span className="signup-error-message">
                {errorMsg}
              </span>
            </div>

            <div
              className="login-button button button-black"
              onClick={submitData}
            >
              <span>SINGUP</span>
            </div>


            <div className="login-switch-section">
              <p className="login-switch-message">
                If you already have an account,
              </p>
              <Link
                className="login-switch-button"
                to={{
                  pathname: "/login"
                }}
              >
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>

    </div>
  )
}


export default Signup;
