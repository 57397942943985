import React from 'react';
import './AppHeader.css';

import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';


import HomeIcon from '../assets/icons/home.svg';
import UserIcon from '../assets/icons/user.svg';



const AppHeader = (props) => {

  const displayButtons = props.displayButtons;


  return (
    <div className="app-header-container">
      <div className="contents-wrapper">
        <div className="app-header-contents">
          <div className="app-header-app-name">
            <span className="app-header-app-name app-header-app-name-main">VIVERTA</span>
            <span className="app-header-app-name app-header-app-name-sub">/ PORTAL</span>
          </div>

          {
            displayButtons == true &&
              <div className="app-header-buttons">
                <Link
                  to={{
                    pathname: "/user/setting"
                  }}
                >
                  <img className="app-header-icon-user" src={UserIcon} alt=""/>
                </Link>
                <Link
                  to={{
                    pathname: "/"
                  }}
                >
                  <img className="app-header-icon-home" src={HomeIcon} alt=""/>
                </Link>
              </div>
          }



        </div>
      </div>
    </div>
  )
}

export default AppHeader;
