import React from 'react';
import logo from './logo.svg';
import './App.css';

import {Helmet} from "react-helmet";
import { Auth } from 'aws-amplify';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from "react-router-dom";
import Home from './screens/Home';
import CreateCommitment from './screens/CreateCommitment';

import Login from './screens/Login';
import Signup from './screens/Signup';
import UserSetting from './screens/UserSetting';
import ViewCommitment from './screens/ViewCommitment';

function App() {

  const [authState, setAuthState] = React.useState({
    isAuthenticated: false,
    isAuthenticating: true,
    user: null
  })

  const authProps = {
    authState: authState,
    setAuthState: setAuthState
  }

  React.useEffect(() => {
    Auth.currentSession()
      .then(response => {
        const session = response;
        setAuthState(authState => ({...authState, isAuthenticated: true}));
        const user = Auth.currentAuthenticatedUser()
          .then(user => {
            setAuthState(authState => ({...authState, user: user}));
            setAuthState(authState => ({...authState, isAuthenticating: false}));
          })
      })
      .catch(error => {
        setAuthState(authState => ({...authState, isAuthenticated: false}));
        setAuthState(authState => ({...authState, user: null}));
        setAuthState(authState => ({...authState, isAuthenticating: false}));
        console.log(error);
      })
  }, [])

  const PrivateRoute = ({ children, ...rest }) => {
    if (authState.isAuthenticating==false) {

      if (authState.isAuthenticated != true) {
        console.log("Need to login");
      }

    return (
      <Route {...rest} render={({location}) => {
        return authState.isAuthenticated
          ? children
          : <Redirect to={{
              pathname: "/login",
            }}/>
      }} />
    )


    }
  }


  return (
    !authState.isAuthenticating &&
    <div className="App">

      <Helmet>
        <title>VIVERTA PORTAL</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
        <meta name="apple-mobile-web-app-capable" content="yes"/>
        <meta name="apple-mobile-web-app-status-bar-style" content="default"/>

          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
          <link rel="manifest" href="/site.webmanifest"/>
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
          <meta name="msapplication-TileColor" content="#da532c"/>
          <meta name="theme-color" content="#ffffff"/>
      </Helmet>


      <Router>
        <Switch>

          <Route exact path="/login" render={(props) =>
            <Login
              {...props}
              auth={authProps}
            />
          }/>

          <Route exact path="/signup" render={(props) =>
            <Signup
              {...props}
              auth={authProps}
            />
          }/>


          <PrivateRoute exact path="/">
            <Home
              auth={authProps}
            />
          </PrivateRoute>



          <PrivateRoute exact path="/commitment/create">
            <CreateCommitment
              auth={authProps}
            />
          </PrivateRoute>

          <PrivateRoute exact path="/commitment/view">
            <ViewCommitment
              auth={authProps}
            />
          </PrivateRoute>



          <PrivateRoute exact path="/user/setting">
            <UserSetting
              auth={authProps}
            />
          </PrivateRoute>






        </Switch>
      </Router>
    </div>
  );
}

export default App;






























//
