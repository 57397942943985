import React from 'react';
import './Home.css';

import { Link } from 'react-router-dom';
import axios from 'axios';

import Data from '../data/data';

import AppHeader from '../components/AppHeader';

import WorkingGray from '../assets/icons/statuses/working.svg';
import AwayGray from '../assets/icons/statuses/away.svg';
import EatingGray from '../assets/icons/statuses/eating.svg';
import BreakGray from '../assets/icons/statuses/break.svg';


const Home = (props) => {

  const [authState, setAuthState] = React.useState(props.auth);

  const [userComment, setUserComment] = React.useState("");

  const [states, setStates] = React.useState({
    "working": {
      timeSpent: 0
    },
    "away": {
      timeSpent: 0
    },
    "eating": {
      timeSpent: 0
    },
    "break": {
      timeSpent: 0
    }
  })

  const [currentState, setCurrentState] = React.useState({
    state: "",
    timeSpent: "",
    timeCalculatedAt: ""
  });

  const [delayState, setDelayState] = React.useState({
    value: "",
    delayed: ""
  })

  const [friendStatus, setFriendStatus] = React.useState("");

  const currentStateRef = React.useRef(currentState);
  currentStateRef.current = currentState;

  React.useEffect(() => {
    console.log(getFormattedTime(states["working"].timeAdjusted * 1000));
  }, [states])


  const [currentCommitment, setCurrentCommitment] = React.useState({})


  const callItADay = () => {

    if (window.confirm("Complete the commitment and call it a day?")) {

      axios.post(`${Data.api_urls.backend}/commitments/complete_commitment`, {
        cognito_id: authState.authState.user.username
      })
      .then(response => {
        console.log(response);
        alert("Commitment complete! Well done 👍")
        getCurrentCommitment();
      })
      .catch(error => {
        console.log(error);
      })

    }


  }


  const updateCurrentState = () => {
    if (currentCommitment != {} && currentCommitment.committed == true) {
      const currentStateCopy = currentStateRef.current;
      const difference = Date.now() - currentStateCopy.timeCalculatedAt;
      console.log("difference:", getFormattedTime(difference))
      const workingStateCopy = states["working"]
      workingStateCopy.timeAdjusted = workingStateCopy.timeSpent + (difference / 1000);
      setStates(states => ({...states, "working": workingStateCopy}));
    }
  }



  React.useEffect(() => {
    console.log(currentCommitment)

    if (currentCommitment != {} && currentCommitment.commitment != undefined ) {


      setDelayState({
        value: currentCommitment.complete_rate - ((((currentCommitment.commitment.duration * 60 * 60) - currentCommitment.remaining) / (currentCommitment.commitment.duration * 60 * 60) * 100).toFixed(0)),
        delayed: (currentCommitment.complete_rate - ((((currentCommitment.commitment.duration * 60 * 60) - currentCommitment.remaining) / (currentCommitment.commitment.duration * 60 * 60) * 100).toFixed(0))) < 0
      })


    }

  }, [currentCommitment])


  const getFriendStatus = () => {
    axios.get(`${Data.api_urls.backend}/commitments/get_others_commitment`, {
      params: {
        cognito_id: authState.authState.user.username
      }
    })
    .then(response => {
      console.log(response)
      setFriendStatus(response.data);
    })
    .catch(error => {
      console.log(error)
    })
  }


  React.useEffect(() => {
    getFriendStatus();
  }, [])


  const getFormattedTime = (milliseconds) => {
    const inMinutes = milliseconds / 60000;

    const hours = Math.floor(inMinutes / 60);

    const minutes = Math.floor((milliseconds % (60 * 60000)) / 60000);

    let formattedMinutes = minutes.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })

    return (`${hours}:${formattedMinutes}`)

  }


  const calculateStatus = (statuses_data) => {

    console.log(statuses_data);

    const current_time = Date.now();

    const newStates = []

    statuses_data.map((value, index) => {


      const status = value;


      if (status.completed == false) {

        const start_time = Date.parse(status.created_at)

        const difference = current_time - start_time;

        const elapsed_time = getFormattedTime(difference);

        console.log(elapsed_time);


        const newState = {
          state: status.status_name,
          timeSpent: elapsed_time
        }

        setCurrentState(newState);

        newStates.push(newState);



      } else {

        const start_time = Date.parse(status.created_at)
        const end_time = Date.parse(status.end_time)

        const difference = end_time - start_time;

        const elapsed_time = getFormattedTime(difference);

        console.log(elapsed_time);

        const newState = {
          state: status.status_name,
          timeSpent: elapsed_time
        }

        newStates.push(newState);

      }
    })

  }


  const checkCurrentState = (statuses, statuses_data) => {
    // from array of statuses, find the one that is not complete yet, and set the state.
    console.log(statuses_data);
    console.log(statuses)

    if (statuses != undefined) {
      statuses.map((status, index) => {
        if (status.completed == false) {
          const newState = {
            state: status.status_name,
            timeSpent: statuses_data[status.status_name].elapsed_time_seconds * 1000,
            timeCalculatedAt: Date.now()
          }
          console.log(newState);
          setCurrentState(newState);
        }
      })
    }


  }


  const checkStates = (statuses_data) => {
    console.log(statuses_data);
    const newStates = states;
    console.log(newStates);
    if (statuses_data != undefined) {
      for (const [key, value] of Object.entries(statuses_data)) {
        newStates[key].timeSpent = value.elapsed_time_seconds
        newStates[key].timeAdjusted = value.elapsed_time_seconds
      }
      setStates(newStates);
    }

  }





  const getCurrentCommitment = () => {

    console.log("getting commitment ...");
    axios.get(`${Data.api_urls.backend}/commitments/${authState.authState.user.username}`)
    .then(response => {
      checkCurrentState(response.data.statuses, response.data.statuses_data);
      checkStates(response.data.statuses_data);
      setCurrentCommitment(response.data)
    })
    .catch(error => {
      console.log(error);
    })

  }


  const getUserComment = () => {
    axios.get(`${Data.api_urls.backend}/users/${authState.authState.user.username}`)
    .then(response => {
      console.log(response.data.user)
      setUserComment(response.data.user.comment);
    })
    .catch(error => {
      console.log(error);
    })
  }


  // const getTime = () => {
  //
  //   const d = new Date();
  //
  //   const dayOfWeek = [
  //     "Sunday",
  //     "Monday",
  //     "Tuesday",
  //     "Wednesday",
  //     "Thursday",
  //     "Friday",
  //     "Saturday"
  //   ]
  //
  //   const months = [
  //     "January",
  //     "February",
  //     "March",
  //     "April",
  //     "May",
  //     "June",
  //     "July",
  //     "August",
  //     "September",
  //     "October",
  //     "November",
  //     "December"
  //   ]
  //
  //   let formattedMinutes = d.getMinutes().toLocaleString('en-US', {
  //     minimumIntegerDigits: 2,
  //     useGrouping: false
  //   })
  //
  //   const newTime = {
  //     year: d.getFullYear(),
  //     month: months[d.getMonth()],
  //     date: d.getDate(),
  //     day: dayOfWeek[d.getDay()],
  //     hour: d.getHours(),
  //     minute: formattedMinutes
  //   }
  //
  //   document.getElementById("day").innerText = newTime.day;
  //   document.getElementById("month-date").innerText = newTime.month + " " + newTime.date;
  //   document.getElementById("year").innerText = newTime.year;
  //   document.getElementById("time").innerText = "/ " + newTime.hour + ":" + newTime.minute;
  // }

  // React.useEffect(() => {
  //   const interval = setInterval(() => getTime(), 1000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [])

  React.useEffect(() => {

    const interval = setInterval(() => updateCurrentState(), 10000);
    return () => {
      clearInterval(interval);
    };

  }, [])

  React.useEffect(() => {

    const interval = setInterval(() => getCurrentCommitment(), 30000);
    return () => {
      clearInterval(interval);
    };

  }, [])

  React.useEffect(() => {
    const interval = setInterval(() => getFriendStatus(), 10000);
    return () => {
      clearInterval(interval);
    };
  }, [])



  React.useEffect(() => {
    getUserComment();
    getCurrentCommitment();
  }, [])



  const changeState = (newStateName) => {
    axios.post(`${Data.api_urls.backend}/statuses`, {

      cognito_id: authState.authState.user.username,
      status_name: newStateName

    })
    .then(response => {
      console.log(response);
      const newState = {
        state: response.data.status.status_name,
        timeSpent: 0,
        timeCalculatedAt: Date.now()
      }
      setCurrentState(newState);
    })
    .catch(error => {
      console.log(error);
    })
  }


  return (
    <div className="app-container">
      <AppHeader
        displayButtons={true}
        auth={authState}
      />
      <div className="page-container">

        {/*
          <div className="home-refresh-container">
            <span>REFRESH</span>
          </div>
        */}

        <div className="contents-wrapper">

          {
            false &&
            <div className="home-time-container element-container">
              <div className="home-time-contents">
                <div className="home-time-left">
                  <p className="home-time-day-of-week" id="day">
                  </p>
                  <p className="home-time-month-year" id="month-date">
                  </p>
                  <p className="home-time-month-year" id="year">
                  </p>
                </div>
                <div className="home-time-right">
                  <p className="home-time-time" id="time">
                  </p>
                </div>
              </div>
            </div>
          }


          <div className="flex-wrapper">

            <div className="home-message-section page-section flex-item">
              <div className="home-header home-header-message">
                <span className="header-title home-header-message-text">
                  WHAT'S UP?
                </span>
                <div className="home-header-message-buttons">
                  <div
                    className="home-header-message-delete-button"
                    onClick={() => {
                      setUserComment("");
                    }}
                  >
                    <span>DELETE</span>
                  </div>
                  <div
                    className="home-header-message-save-button"
                    onClick={() => {
                      axios.post(`${Data.api_urls.backend}/users/update_comment`, {
                        cognito_id: authState.authState.user.username,
                        comment: userComment
                      })
                      .then(response => {
                        console.log(response)
                        alert("Message saved.")
                        getUserComment();
                      })
                      .catch(error => {
                        console.log(error);
                      })
                    }}
                  >
                    <span>SAVE</span>
                  </div>
                </div>

              </div>
              <div className="home-message-content">
                <textarea
                  type="text"
                  className="home-message-content-input element-container"
                  value={userComment}
                  onChange={(e) => {
                    setUserComment(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="home-today-section page-section flex-item">
              <div className="home-header home-header-message">
                <span className="header-title home-header-today-text">
                  TODAY
                </span>
              </div>


              {(() => {
                if (currentCommitment != {} && currentCommitment.committed == true) {
                  return (
                    <div className="home-today-card">
                      <div className="home-today-content-top element-container">
                        <div className="home-today-content-left">
                          <div className="home-today-task-section">
                            <span className="home-today-task-number">{currentCommitment.complete_rate}%</span>
                            <span className="home-today-task-text">TASKS<br/>COMPLETED</span>
                          </div>
                        </div>
                        <div className="home-today-content-line"></div>


                        {
                          currentCommitment.complete_rate < 100
                          ?
                            <div className="home-today-content-right">
                              <div className={`home-today-task-delay ${delayState.delayed ? "bad" : "good"}`}>
                                <span className="home-today-task-delay-number">{Math.abs(delayState.value)}%</span>
                                <span className="home-today-task-delay-letters">{delayState.delayed ? "delayed" : "ahead"}</span>
                              </div>
                              <span className="home-today-task-delay-message">{delayState.delayed ? "⚠️ Hurry up!" : "👍 keep it going!"}</span>
                            </div>
                          :
                            <div className="home-today-content-right">
                              <div className={`home-today-task-delay good`}>
                                <span className="home-today-task-delay-letters">completed</span>
                              </div>
                              <span className="home-today-task-delay-message">👍 Well done!</span>
                            </div>
                        }




                      </div>
                      <div className="line"></div>
                      <div className="home-today-content-bottom element-container">
                        <div className="home-today-hours-section">
                          <p className="home-today-hours-text">WORKING HOURS</p>
                          <div className="home-today-hours-row">
                            <span className="home-today-hours-current">{(currentCommitment.remaining / 60 / 60).toFixed(1)} hrs.</span>
                            <span className="home-today-hours-remainig"> to go 🔥</span>
                          </div>
                          <div className="home-todya-hours-percent">
                            <span className="home-today-hours-remainig-percent">{(((currentCommitment.commitment.duration * 60 * 60) - currentCommitment.remaining) / (currentCommitment.commitment.duration * 60 * 60) * 100).toFixed(0)}% elapsed.</span>
                          </div>
                        </div>
                        <div
                          className="home-today-view-button button button-black"
                          onClick={() => {
                            callItADay();
                          }}
                        >
                          <span>CALL IT A DAY</span>
                        </div>
                      </div>
                    </div>
                  )
                } else {
                  return (
                    <div className="home-today-card">
                      <div className="home-today-content element-container">

                        <div className="home-today-empty-mesage-wrapper">
                          <p>
                            You don’t have any <strong>Commitment</strong>. <br/>
                            Create one to start your day.
                          </p>
                        </div>

                        <Link
                          to={{
                            pathname: "/commitment/create"
                          }}
                          className="home-today-view-button button button-black"
                        >
                          <span>NEW COMMITMENT</span>
                        </Link>
                      </div>
                    </div>
                  )
                }
              })()}






            </div>

          </div>




          <div className="home-status-section page-section">
            <div className="home-header home-header-status">
              <span className="header-title home-header-status-text">
                STATUS
              </span>
            </div>

            {(() => {
              if (currentCommitment != {} && currentCommitment.committed == true) {
                return (
                  <div className="home-status-content">
                    <div
                      className={currentState.state == "working" ? "home-status-section home-status-section-black" : "home-status-section home-status-section-gray"}
                      onClick={() => {
                        changeState("working");
                      }}
                    >
                      <div className="home-status-button">
                        <img className="home-status-button-icon" src={WorkingGray} alt=""/>
                      </div>
                      <div className="home-status-texts">
                        <p className="home-status-text-name">
                          /working
                        </p>
                        <p className="home-status-text-time">
                          {console.log(states["working"])}
                          {getFormattedTime(states["working"].timeAdjusted * 1000)}
                        </p>
                      </div>
                    </div>
                    <div
                      className={currentState.state == "away" ? "home-status-section home-status-section-black" : "home-status-section home-status-section-gray"}
                      onClick={() => {
                        changeState("away");
                      }}
                    >
                      <div className="home-status-button">
                        <img className="home-status-button-icon" src={AwayGray} alt=""/>
                      </div>
                      <div className="home-status-texts">
                        <p className="home-status-text-name">
                          /away
                        </p>
                        <p className="home-status-text-time">
                          {getFormattedTime(states["away"].timeAdjusted * 1000)}
                        </p>
                      </div>
                    </div>
                    <div
                      className={currentState.state == "eating" ? "home-status-section home-status-section-black" : "home-status-section home-status-section-gray"}
                      onClick={() => {
                        changeState("eating");
                      }}
                    >
                      <div className="home-status-button">
                        <img className="home-status-button-icon" src={EatingGray} alt=""/>
                      </div>
                      <div className="home-status-texts">
                        <p className="home-status-text-name">
                          /eating
                        </p>
                        <p className="home-status-text-time">
                          {getFormattedTime(states["eating"].timeAdjusted * 1000)}
                        </p>
                      </div>
                    </div>
                    <div
                      className={currentState.state == "break" ? "home-status-section home-status-section-black" : "home-status-section home-status-section-gray"}
                      onClick={() => {
                        changeState("break");
                      }}
                    >
                      <div className="home-status-button">
                        <img className="home-status-button-icon" src={BreakGray} alt=""/>
                      </div>
                      <div className="home-status-texts">
                        <p className="home-status-text-name">
                          /break
                        </p>
                        <p className="home-status-text-time">
                          {getFormattedTime(states["break"].timeAdjusted * 1000)}
                        </p>
                      </div>
                    </div>

                  </div>
                )
              } else {
                return (
                  <div className="home-status-content-center">
                    <span className="home-status-message">Create <strong>Commitment</strong> to start recording your status.</span>
                  </div>
                )
              }
            })()}

            {
              friendStatus != "" &&
              <div className="home-friend-status-section">
                {
                  friendStatus.commited != false
                  ? <div>
                      <span className="home-friend-status-name">{friendStatus.user_name} |</span>
                      <span className="home-friend-status-status"> /{friendStatus.current_status.status_name}</span>
                      <span className="home-friend-status-completion"> | {friendStatus.complete_rate}%</span>
                      <span className="home-friend-status-time"> | {getFormattedTime(friendStatus.worked_hours * 1000)}</span>
                    </div>
                  : <span className="home-friend-status-no-commitment-message">Your friend has not made commitment yet.</span>


                }

                <p className="home-friend-status-comment-description">
                  Your frined is saying ...
                </p>
                <p className="home-friend-status-comment">
                  {friendStatus.comment}
                </p>
              </div>
            }

          </div>



        </div>

      </div>
    </div>
  )
}


export default Home;












//
