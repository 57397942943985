import React from 'react';
import './Login.css';

import { Auth } from 'aws-amplify';
import { Link, Redirect } from 'react-router-dom';

import AppHeader from '../components/AppHeader';

const Login = (props) => {

  const [redirect, setRedirect] = React.useState(false);

  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
  });

  const [errorMsg, setErrorMsg] = React.useState("");

  const [authState, setAuthState] = React.useState(props.auth.authState);

  const submitData = () => {

    Auth.signIn(formData.email, formData.password)
    .then (user => {
      console.log("success: ", user);
      props.auth.setAuthState(authState => ({...authState, isAuthenticated: true}));
      props.auth.setAuthState(authState => ({...authState, user: user}));
      setRedirect(true);
    })
    .catch(error => {
      console.log("error: ", error);

      switch (error.code) {
        case "NotAuthorizedException":
          setErrorMsg("メールアドレスかパスワードが間違っています。")
          break;
        case "UserNotFoundException":
          setErrorMsg("メールアドレスかパスワードが間違っています。")
          break;
        case "UserNotConfirmedException":
        setErrorMsg("メールを確認してアカウントを認証してください。")
        break;
        default:
          setErrorMsg("ログインに失敗しました")

      }
    })
  }

  if (redirect == true) {
    return (
      <Redirect
        to={{
          pathname: "/"
        }}
      />
    )
  }




  return (
    <div className="app-container">
      <AppHeader displayButtons={false}/>

      <div className="page-container">
        <div className="contents-wrapper">
          <div className="page-section">
            <div className="home-header">
              <span className="header-title">
                MEMBER LOGIN
              </span>
            </div>
            <div className="login-form">
              <span className="login-form-title">
                EMAIL
              </span>
              <input
                className="login-form-input"
                type="text"
                onChange={(e) => {
                  setFormData(formData => ({...formData, email: e.target.value}))
                }}
              />
            </div>
            <div className="login-form">
              <span className="login-form-title">
                PASSWORD
              </span>
              <input
                className="login-form-input"
                type="password"
                onChange={(e) => {
                  setFormData(formData => ({...formData, password: e.target.value}))
                }}
              />
            </div>

            <div className="login-buttons-section">

              <div className="signup-error-section">
                <span className="signup-error-message">
                  {errorMsg}
                </span>
              </div>

              <div
                className="login-button button button-black"
                onClick={submitData}
              >
                <span>LOGIN</span>
              </div>
              <div className="login-switch-section">
                <p className="login-switch-message">
                  If you don't have an account,
                </p>
                <Link
                  className="login-switch-button"
                  to={{
                    pathname: "/signup"
                  }}
                >
                  Signup
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}


export default Login;
