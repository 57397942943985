import React from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import './UserSetting.css';

import Data from '../data/data.js';
import AppHeader from '../components/AppHeader';


const UserSetting = (props) => {
  const [authState, setAuthState] = React.useState(props.auth);
  const [setting, setSetting] = React.useState("");

  const getData = () => {
    console.log("getting data");
    axios.get(`${Data.api_urls.backend}/users/get_settings`, {
      params: {
        cognito_id: authState.authState.user.username
      }
    })
    .then(response => {
      console.log(response.data);
      setSetting(response.data);
    })
    .catch(error => {
      console.log(error);
    })
  }

  const submitData = () => {
    axios.post(`${Data.api_urls.backend}/users/save_settings`, {
      cognito_id: authState.authState.user.username,
      settings: setting
    })
    .then(response => {
      console.log(response);
      alert("Saved.")
    })
    .catch(error => {
      console.log(error);
    })
  }

  React.useEffect(() => {
    getData();
  }, [])

  return(
    <div className="app-container">
      <AppHeader
        displayButtons={true}
        auth={authState}
      />
      <div className="page-container">
        <div className="contents-wrapper">
          <div className="page-section">
            <div className="home-header">
              <span className="header-title home-header-message-text">
                USER SETTING
              </span>
            </div>

            {
              setting != "" &&
              <div className="use-setting-contents">

                <div className="user-setting-section">
                  <div className="home-sub-header">
                    <span className="header-sub-title home-header-message-text">
                      BASIC
                    </span>
                  </div>
                  <div className="user-setting-form">
                    <span className="user-setting-form-label">/ NAME</span>
                    <input
                      className="default-input user-setting-input"
                      type="text"
                      defaultValue={setting.user_name}
                      onChange={(e) => {
                        setSetting(setting => ({...setting, user_name: e.target.value}))
                      }}
                    />
                  </div>
                </div>

                <div className="user-setting-section">
                  <div className="home-sub-header">
                    <span className="header-sub-title home-header-message-text">
                      INTEGRATION
                    </span>
                  </div>
                  <div className="user-setting-form">
                    <span className="user-setting-form-label">/ TABLE ID <span className="user-setting-form-lable-small">NOTION</span></span>
                    <input
                      className="default-input user-setting-input"
                      type="text"
                      defaultValue={setting.notion_setting.table_id}
                      onChange={(e) => {
                        const newNotionSetting = setting.notion_setting
                        newNotionSetting.table_id = e.target.value
                        setSetting(setting => ({...setting, notion_setting: newNotionSetting}))
                      }}
                    />
                  </div>
                  <div className="user-setting-form">
                    <span className="user-setting-form-label">/ TODAY PROPERTY NAME <span className="user-setting-form-lable-small">NOTION</span></span>
                    <input
                      className="default-input user-setting-input"
                      type="text"
                      defaultValue={setting.notion_setting.today_property_name}
                      onChange={(e) => {
                        const newNotionSetting = setting.notion_setting
                        newNotionSetting.today_property_name = e.target.value
                        setSetting(setting => ({...setting, notion_setting: newNotionSetting}))
                      }}
                    />
                  </div>
                  <div className="user-setting-form">
                    <span className="user-setting-form-label">/ TODAY KEYWORD <span className="user-setting-form-lable-small">NOTION</span></span>
                    <input
                      className="default-input user-setting-input"
                      type="text"
                      defaultValue={setting.notion_setting.today_property_value}
                      onChange={(e) => {
                        const newNotionSetting = setting.notion_setting
                        newNotionSetting.today_property_value = e.target.value
                        setSetting(setting => ({...setting, notion_setting: newNotionSetting}))
                      }}
                    />
                  </div>
                  <div className="user-setting-form">
                    <span className="user-setting-form-label">/ COMPLETION PROPERTY NAME <span className="user-setting-form-lable-small">NOTION</span></span>
                    <input
                      className="default-input user-setting-input"
                      type="text"
                      defaultValue={setting.notion_setting.completion_property_name}
                      onChange={(e) => {
                        const newNotionSetting = setting.notion_setting
                        newNotionSetting.completion_property_name = e.target.value
                        setSetting(setting => ({...setting, notion_setting: newNotionSetting}))
                      }}
                    />
                  </div>
                  <div className="user-setting-form">
                    <span className="user-setting-form-label">/ COMPLETION KEYWORD <span className="user-setting-form-lable-small">NOTION</span></span>
                    <input
                      className="default-input user-setting-input"
                      type="text"
                      defaultValue={setting.notion_setting.completion_property_value}
                      onChange={(e) => {
                        const newNotionSetting = setting.notion_setting
                        newNotionSetting.completion_property_value = e.target.value
                        setSetting(setting => ({...setting, notion_setting: newNotionSetting}))
                      }}
                    />
                  </div>

                </div>

                <div
                  className="button button-black"
                  onClick={() => {
                    submitData();
                  }}
                >
                  <span>SAVE</span>
                </div>
                <div
                  className="button button-skelton"
                  onClick={() => {
                    Auth.signOut()
                    .then(() => {
                      console.log("signed out");
                      props.auth.setAuthState(authState => ({...authState, isAuthenticated: false}));
                      props.auth.setAuthState(authState => ({...authState, user: null}));
                    })
                    .catch(error => {
                      console.log(error);
                    })
                  }}
                >
                  <span>LOGOUT</span>
                </div>

              </div>
            }





          </div>
        </div>
      </div>
    </div>
  )
}

export default UserSetting;
