const Data = {
  notion: {
    api_key: "secret_J6qO17AZizxrCAJlCAc8cN62hoDvnppNMxvjlLgEtQw",
    table_id: "459386049d8f4b8d8c4f15acbae7b858"
  },
  api_urls: {
    backend: "https://vvrt-portal-back.herokuapp.com"
    // backend: "http://127.0.0.1:4000"
  }
}

export default Data;
